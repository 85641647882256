import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn2,
  Overlay,
  CloseBtn,
} from "components/parts";
import { MoreBtn2Container } from "components/parts/MoreBtn2";

import HeaderDots from "../../shared/HeaderDots";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";
import img3 from "./assets/img3.jpg";
import img4 from "./assets/img4.jpg";
import img5 from "./assets/img5.jpg";
import img6 from "./assets/img6.jpg";

import overlay1_img1 from "./assets/overlay1_img1.jpg";
import overlay2_img1 from "./assets/overlay2_img1.jpg";
import overlay2_img2 from "./assets/overlay2_img2.jpg";
import overlay3_img1 from "./assets/overlay3_img1.jpg";
import overlay4_img1 from "./assets/overlay4_img1.jpg";
import overlay4_img2 from "./assets/overlay4_img2.png";
import overlay4_img3 from "./assets/overlay4_img3.jpg";

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    color: #fff !important;
    font-size: 29px;
    line-height: 40px;
    margin-right: 0;
    margin-top: 26px;
  }

  > div {
    .box1 {
      h2 {
        font-weight: bold;
        margin: 0 0 5px 0;
        transition: all 1s ease-out;
      }

      p {
        font-size: 16px;
        line-height: 21px;
        transition: all 1s ease-out;
      }
    }
  }

  .box1-1 {
  }

  .btn1_1 {
    transition: all 1s ease-out;
    opacity: 0;
  }

  /* */

  .img1 {
    position: absolute;
    left: 232px;
    top: 175px;
    width: 550px;
    transition: all 1s ease-out;

    &.img1_2,
    &.img1_3,
    &.img1_4,
    &.img1_5,
    &.img1_6 {
      opacity: 0;
    }
  }

  .overlay {
    &:before {
      background: #051f4a;
    }

    .bg1 {
      background: #fff;
      border-radius: 36px;
      overflow: hidden;
    }

    .bg2 {
      background: #fff;
      border-radius: 0;
      overflow: hidden;

      video {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }

  .overlay1 {
    > .bg1 {
      &::before {
        position: absolute;
        width: 100%;
        height: 83px;
        left: 0;
        bottom: 0;
        background: #0a7cc1;
        opacity: 0.1;
        content: "";
      }
    }

    .box1 {
      font-size: 23px;
    }

    .box2 {
      font-size: 20px;
      line-height: 25px;
    }

    .box3 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .overlay2 {
    .box1 {
      font-size: 23px;
      line-height: 29px;
    }

    .box2 {
      font-size: 17px;
      line-height: 25px;
    }
  }

  .overlay3 {
    .box1 {
      font-size: 23px;
      line-height: 29px;
    }

    .box2 {
      font-size: 21px;
      background: rgba(10, 124, 193, 0.1);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 68px;
    }

    .box3 {
      font-size: 8px;
      line-height: 10px;
    }

    .box4 {
      font-size: 21px;
      background: rgba(10, 124, 193, 0.1);
      text-align: center;
    }

    .mask {
      background: #fff;
    }
  }

  .overlay4 {
    .box1 {
      font-size: 23px;
      line-height: 29px;
    }

    .box1 {
      font-size: 23px;
    }
  }

  /* */

  .copy-end {
    font-size: 8px;
    line-height: 10px;
    transition: all 1s ease-out;
    opacity: 0;
  }

  /* */
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  let step = props.step;

  //step = 6;

  useEffect(() => {
    toggleOverlay(null);
  }, [step]);

  useEffect(() => {
    var mediaElement = document.getElementById("video");
    if (mediaElement) {
      if (state.overlay1 === "overlay5") {
        mediaElement.play();
      } else {
        mediaElement.pause();
        mediaElement.currentTime = 0;
      }
    }
  }, [state.overlay1]);

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1 className="color2">
          ACUVUE® Kontaktlinsen für Astigmatismus sind die einzigen torischen
          <br />
          Kontaktlinsen mit der{" "}
          <strong>
            Lidschlag-Stabilisations-Design Technologie<sup>1</sup>
          </strong>
        </h1>

        <HeaderDots top={268} />

        <img
          src={img1}
          className="img1 img1_1"
          style={{ opacity: step === 1 ? 1 : 0 }}
        />

        <img
          src={img2}
          className="img1 img1_2"
          style={{ opacity: step === 2 ? 1 : 0 }}
        />

        <img
          src={img4}
          className="img1 img1_4"
          style={{ opacity: step === 3 ? 1 : 0 }}
        />

        <img
          src={img5}
          className="img1 img1_5"
          style={{ opacity: step === 4 ? 1 : 0 }}
        />

        <img
          src={img3}
          className="img1 img1_3"
          style={{ opacity: step === 5 ? 1 : 0 }}
        />

        <img
          src={img6}
          className="img1 img1_6"
          style={{ opacity: step === 6 ? 1 : 0 }}
        />

        <Container left={100} top={390} width={440} className="box1 box1-1">
          <h2
            className="color2"
            style={{ opacity: step <= 2 || step >= 6 ? 1 : 0.2 }}
          >
            Vier Stabilisierungszonen
          </h2>
          <div className="fadeIn">
            <p
              className="color3"
              style={{ opacity: step === 2 || step === 6 ? 1 : 0 }}
            >
              die sich bei jedem Lidschlag auf natürliche Weise neu ausrichten
              und so den ganzen Tag über eine{" "}
              <strong>
                gleichblei-bende, klare und stabile
                <br />
                Sicht{" "}
              </strong>
              gewährleisten, selbst
              <br />
              bei starken Kopf- und Augenbewegungen<sup>1</sup>
            </p>
          </div>
        </Container>
        <MoreBtn2
          left={550}
          top={394}
          onClick={() => toggleOverlay("overlay1")}
          className="btn1_1"
          style={{ opacity: step === 2 || step === 6 ? 1 : 0 }}
        />

        <Container left={100} top={933} width={440} className="box1 box1-1">
          <h2
            className="color2"
            style={{
              opacity: step === 1 || step === 3 || step === 6 ? 1 : 0.2,
            }}
          >
            Minimale untere Lidkanteninteraktion
          </h2>
          <div className="fadeIn">
            <p
              className="color3"
              style={{ opacity: step === 3 || step === 6 ? 1 : 0 }}
            >
              zur{" "}
              <strong>
                Minimierung
                <br />
                unerwünschter Rotation<sup>‡1</sup>
              </strong>
            </p>
          </div>
        </Container>
        <MoreBtn2
          left={550}
          top={928}
          onClick={() => toggleOverlay("overlay3")}
          className="btn1_1"
          style={{ opacity: step === 3 || step === 6 ? 1 : 0 }}
        />

        <Container left={1499} top={933} width={480} className="box1 box1-1">
          <h2
            className="color2"
            style={{
              opacity: step === 1 || step === 4 || step === 6 ? 1 : 0.2,
            }}
          >
            Horizontale und
            <br />
            vertikale Symmetrie
          </h2>
          <div className="fadeIn">
            <p
              className="color3"
              style={{ opacity: step === 4 || step === 6 ? 1 : 0 }}
            >
              für ein <strong>problemloses Aufsetzen</strong> und nur minimale
              Beeinflussung durch Schwerkraft<sup>1</sup>
            </p>
          </div>
        </Container>
        <MoreBtn2
          left={1389}
          top={928}
          onClick={() => toggleOverlay("overlay4")}
          className="btn1_1"
          style={{ opacity: step === 4 || step === 6 ? 1 : 0 }}
        />

        <Container left={1499} top={390} width={480} className="box1 box1-1">
          <h2
            className="color2"
            style={{
              opacity: step === 1 || step === 5 || step === 6 ? 1 : 0.2,
            }}
          >
            Prismenfreie
            <br />
            optische Zone
          </h2>
          <div className="fadeIn">
            <p
              className="color3"
              style={{ opacity: step === 5 || step === 6 ? 1 : 0 }}
            >
              zur{" "}
              <strong>
                Vermeidung
                <br />
                vertikaler Heterophorie
              </strong>
              <sup>*1</sup>
            </p>
          </div>
        </Container>
        <MoreBtn2
          left={1389}
          top={404}
          onClick={() => toggleOverlay("overlay2")}
          className="btn1_1"
          style={{ opacity: step === 5 || step === 6 ? 1 : 0 }}
        />

        <MoreBtn2
          left={1888}
          top={226}
          onClick={() => toggleOverlay("overlay5")}
          className="btn1_1"
          style={{
            opacity: step === 6 && state.overlay1 !== "overlay5" ? 1 : 0,
          }}
          exclamation
          fill="#ff7641"
        />

        <Overlay
          active={state.overlay1 === "overlay1"}
          className="overlay with-bg overlay1"
        >
          <Container
            left={122}
            top={113}
            width={1802}
            height={1050}
            className="bg1"
          />

          <Container
            left={306}
            top={151}
            width={1418}
            className="box1 color2 text-center"
          >
            <strong>
              Das Leben besteht nicht darin, nur geradeaus zu schauen
            </strong>
          </Container>

          <Image
            src={overlay1_img1}
            width={864}
            height={716}
            left={595}
            top={249}
          />

          <Container
            left={170}
            top={345}
            width={444}
            className="box2 color2 text-center"
          >
            Lidschlag-
            <br />
            Stabilisations-Design
          </Container>

          <Container
            left={1440}
            top={345}
            width={444}
            className="box2 color2 text-center"
          >
            Traditionelles
            <br />
            Prismenballast-Design
          </Container>

          <Container
            left={225}
            top={1033}
            width={1600}
            className="box3 color2 text-center"
          >
            ACUVUE® verfügt über das{" "}
            <strong>Lidschlag-Stabilisations-Design</strong>, das den
            <br />
            Auswirkungen der Schwerkraft oder Kopfbewegungen standhält
            <sup>1</sup>
          </Container>

          <CloseBtn left={1820} top={146} onClick={() => toggleOverlay(null)} />
        </Overlay>

        <Overlay
          active={state.overlay1 === "overlay2"}
          className="overlay with-bg overlay2"
        >
          <Container
            left={122}
            top={113}
            width={1802}
            height={1050}
            className="bg1"
          />

          <Container
            left={255}
            top={151}
            width={1519}
            className="box1 color2 text-center"
          >
            ACUVUE® Kontaktlinsen für Astigmatismus mit Lidschlag-
            <br />
            Stabilisations-Design verfügen über eine{" "}
            <strong>
              prismenfreie optische Zone
              <sup>*1</sup>
            </strong>
          </Container>

          <Image
            src={overlay2_img1}
            width={1379}
            height={336}
            left={305}
            top={311}
          />

          <Image
            src={overlay2_img2}
            width={1488}
            height={440}
            left={319}
            top={699}
            className="fadeIn"
          />

          <Container
            left={285}
            top={684}
            width={1472}
            className="box2 color2 text-center fadeIn"
          >
            Etwa 50 % der Kunden haben einen Astigmatismus von ≥ 0,75 dpt auf
            einem Auge<sup>10</sup>
          </Container>

          <CloseBtn left={1820} top={146} onClick={() => toggleOverlay(null)} />
        </Overlay>

        <Overlay
          active={state.overlay1 === "overlay3"}
          className="overlay with-bg overlay3"
        >
          <Container
            left={122}
            top={113}
            width={1802}
            height={1050}
            className="bg1"
          >
            <Container
              left={0}
              top={871}
              width={1802}
              height={140}
              className="box4 color2 flex-all-center  fadeIn animate4"
            >
              <strong>
                Das Lidschlag-Stabilisations-Design sorgt bei jedem
                <br />
                Lidschlag für eine natürliche Neuausrichtung<sup>1</sup>
              </strong>
            </Container>
          </Container>

          <Container
            left={220}
            top={166}
            width={1604}
            className="box1 color2 text-center"
          >
            Das <strong>Lidschlag-Stabilisations-Design</strong> sorgt für eine
            minimale Interaktion
            <br /> mit der unteren Lidkante und trägt dazu bei, eine
            unerwünschte Rotation
            <br />
            zu minimieren<sup>*1</sup>
          </Container>

          <Container
            left={122}
            top={342}
            width={735}
            height={85}
            className="box2 color2 rounded-full flex-all-center"
          >
            <strong>Während des Lidschlags...</strong>
          </Container>

          <Image
            src={overlay3_img1}
            width={1572}
            height={497}
            left={305}
            top={464}
          />

          <Container
            left={284}
            top={472}
            width={402}
            height={433}
            className="mask fadeOut"
          />

          <Container
            left={686}
            top={472}
            width={541}
            height={433}
            className="mask fadeOut animate2"
          />

          <Container
            left={1229}
            top={472}
            width={653}
            height={501}
            className="mask fadeOut animate3"
          />

          <Container
            left={154}
            top={926}
            width={1064}
            height="85"
            className="box3 color2 fadeIn animate4"
          >
            * Traditional prism and peri-ballast lens designs have a thicker
            area below lower eyelid than ACUVUE® Brand Contact Lenses
            <br />
            for Astigmatism that can cause interaction and may cause unwanted
            rotation due to lateral movement of the lower lid during blinks.
          </Container>

          <CloseBtn left={1820} top={146} onClick={() => toggleOverlay(null)} />
        </Overlay>

        <Overlay
          active={state.overlay1 === "overlay4"}
          className="overlay with-bg overlay4"
        >
          <Container
            left={122}
            top={113}
            width={1802}
            height={1050}
            className="bg1"
          />

          <Container
            left={220}
            top={166}
            width={1604}
            className="box1 color2 text-center"
          >
            Das Lidschlag-Stabilisations-Design zeichnet sich durch{" "}
            <strong>horizontale und vertikale Symmetrie</strong> aus und
            ermöglicht so eine mühelose Anwendung, sodass die Kontaktlinse nur
            minimal von der Schwerkraft beeinflusst wird<sup>1</sup>
          </Container>

          <Image
            src={overlay4_img1}
            width={1500}
            height={715}
            left={229}
            top={432}
          />

          <Image
            src={overlay4_img3}
            width={349}
            height={199}
            left={260}
            top={804}
            className="breatheAnimation"
          />

          <Image
            src={overlay4_img2}
            width={218}
            height={218}
            left={329}
            top={572}
            className="breatheAnimation"
          />

          <CloseBtn left={1820} top={146} onClick={() => toggleOverlay(null)} />
        </Overlay>

        <Overlay
          active={state.overlay1 === "overlay5"}
          className="overlay with-bg overlay5"
        >
          <Container
            left={137}
            top={179}
            width={1768}
            height={994}
            className="bg2"
          >
            <video id="video" width="1920" height="1080" controls loop>
              <source
                src="/media/Sales_Folder_DAT_Video_Vier_Stabilisationszonen_241219_LOW.mp4"
                type="video/mp4"
              />
            </video>
          </Container>
          <CloseBtn
            left={1872}
            top={234}
            onClick={() => toggleOverlay(null)}
            bgColor="#fff"
          />
        </Overlay>

        <Container
          left={100}
          top={1182}
          width={1780}
          className="copy-end color3 copy-2"
          style={{ opacity: step === 3 ? 1 : 0 }}
        >
          <br />
          <br />‡ unwanted rotation due to lateral movement of the lower lid
          during blinks.
        </Container>

        <Container
          left={100}
          top={1182}
          width={1780}
          className="copy-end color3 copy-2"
          style={{ opacity: step === 5 ? 1 : 0 }}
        >
          *“Vertical heterophoria possibly caused by prism dissociation due to
          the presence of induced optical prism is a relevant factor for
          practitioners to consider when fitting toric contact lenses for
          monocular astigmats or those requiring a mix of toric soft contact
          lens designs e.g., mixed prescriptions.2,3 Clinical studies have not
          been done to fully characterize the clinical effects of differences in
          base down prism among different contact lenses.” 2. Jackson D et al.
          Vertical heterophoria and susceptibility to visually induced motion
          sickness. Strabismus. 2012;20(1):17-23. 3. Du Toit R et al. Tolerance
          to prism induced by readymade spectacles: setting and using a
          standard. OVS 2007;84(11):1053-9.
        </Container>

        <Container
          left={100}
          top={1182}
          width={1780}
          className="copy-end color3 copy-2"
          style={{ opacity: step === 6 ? 1 : 0 }}
        >
          ‡ unwanted rotation due to lateral movement of the lower lid during
          blinks. *“Vertical heterophoria possibly caused by prism dissociation
          due to the presence of induced optical prism is a relevant factor for
          practitioners to consider when fitting toric contact lenses for
          monocular astigmats or those requiring a mix of toric soft contact
          lens designs e.g., mixed prescriptions.2,3 Clinical studies have not
          been done to fully characterize the clinical effects of differences in
          base down prism among different contact lenses.” 2. Jackson D et al.
          Vertical heterophoria and susceptibility to visually induced motion
          sickness. Strabismus. 2012;20(1):17-23. 3. Du Toit R et al. Tolerance
          to prism induced by readymade spectacles: setting and using a
          standard. OVS 2007;84(11):1053-9.
        </Container>
      </div>
    </Slide>
  );
};
